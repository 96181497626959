import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "bootstrap-material-design/dist/css/bootstrap-material-design.min.css";
import "quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";

const Home = lazy(() => import("./pages/Home"));
const Book = lazy(() => import("./pages/Book"));
const Schedule = lazy(() => import("./pages/Schedule"));
const Payment = lazy(() => import("./pages/Payment"));

function App() {
  return (
    <>
      <Suspense
        fallback={
          <div className="col text-center p-5">Loading the page...</div>
        }
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/book" component={Book} />
          <Route exact path="/schedule/:schedType" component={Schedule} />
          <Route exact path="/payment" component={Payment} />
        </Switch>
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
